import { API } from 'aws-amplify';
import { sendActivityReminder, sendDashboardBuildNotification, sendDashboardUpdateNotification, sendDashboardChangeReminder, sendRssBuildNotification, sendRssFeedDeleteNotification, sendThirdPartyUpdateNotification, sendThirdPartyDeleteNotification, updateClientInfo, sendMemberProfileUpdateNotification } from '../../graphql/mutations';

export const sendActivityReminderNotification = async(familyId, contactId) => {
    const { data, errors } = await API.graphql({ query: sendActivityReminder, variables: { familyId, contactId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const sendReminderDashboardChangeNotification = async(familyId, contactId) => {
    const { data, errors } = await API.graphql({ query: sendDashboardChangeReminder, variables: { familyId, contactId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const dashboardBuildNotification = async( emails,application ) => {
    const { data, errors } = await API.graphql({ query: sendDashboardBuildNotification, variables: { emails,application }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const dashboardUpdateNotification = async( emails,application,action,languauge ) => {
    const { data, errors } = await API.graphql({ query: sendDashboardUpdateNotification, variables: { emails,application,action,languauge }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const rssBuildNotification = async( emails, rssId ) => {
    const { data, errors } = await API.graphql({ query: sendRssBuildNotification, variables: { emails, rssId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const sendDeleteRssBuildNotification = async( emails, rssId ) => {
    const { data, errors } = await API.graphql({ query: sendRssFeedDeleteNotification, variables: { emails, rssId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const thirdPartyUpdateNotification = async( emails ) => {
    const { data, errors } = await API.graphql({ query: sendThirdPartyUpdateNotification, variables: { emails }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const thirdPartyDeleteNotification = async( emails ) => {
    const { data, errors } = await API.graphql({ query: sendThirdPartyDeleteNotification, variables: { emails }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const updateClientProfileInfo = async( contactId, email, phone, familyName, givenName ) => {
    const { data, errors } = await API.graphql({ query: updateClientInfo, variables: { contactId, email, phone, familyName, givenName }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const memberProfileUpdateNotification = async( email ) => {
    const { data, errors } = await API.graphql({ query: sendMemberProfileUpdateNotification, variables: { email }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}